import React, { useState } from 'react';
import { Row, Container, Modal, Button, Col } from 'react-bootstrap';
import videoThumb from '../../assets/images/video-thumb.jpg';
import iconHand from '../../assets/images/icon-handshake-shield.jpg';
import whoIcon from '../../assets/images/icon-who.png';
import whatIcon from '../../assets/images/icon-what.png';
import howIcon from '../../assets/images/icon-how.png';

import './about.scss';
import { Link } from 'gatsby';
import * as $ from 'jquery';

/* eslint-disable-next-line */
export interface AboutProps {}

export function counter(id, start, end, duration) {
  let current = start;
  const obj = document.getElementById(id),
   range = end - start,
   increment = end > start ? 1 : -1,
   step = Math.abs(Math.floor(duration / range)),
   timer = setInterval(() => {
    current += increment;
    obj.textContent = current;
    if (current === end) {
     clearInterval(timer);
    }
   }, step);
 }
export function About(props: AboutProps) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="abous-us" id="about">
      <Container>
        <div className="contenImage">
          <Row>
            <Col lg="8" md="7">
              <div className="content-right"
              data-sal="fade"
              data-sal-duration="1000"
              data-sal-easing="ease"
              >
                <h2>The First SaaS Federated Trust Management Platform</h2>
                <p>
                  We use identity to manage trust and secure critical data for
                  small and medium sized businesses at 1/10th the cost and
                  complexity of current solutions.
                </p>
                <Link className="btn btn-primary" to="/contact">
                  Learn More
                </Link>
              </div>
            </Col>
            <Col lg="4" md="5">
              <div className="imageHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="300"
              >
                <img src={iconHand} alt="icon handshake" />
              </div>
            </Col>
          </Row>
        </div>

      </Container>
      <div className="alt-bg">
        <Container>
        <div className="iconContent text-center">
          <h2>Federated Trust Management</h2>
          <p>control your information – everywhere you do business</p>
          <Row>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={whoIcon} alt="" />
                <div className="icontext">
                  <h3>Who</h3>
                  <p>has access</p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={whatIcon} alt="" />
                <div className="icontext">
                  <h3>What</h3>
                  <p>they can access</p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={howIcon} alt="" />
                <div className="icontext">
                  <h3>How</h3>
                  <p>they can access it</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        </Container>
      </div>
      <div className="businessRow">
        <Container>
          <div className="heading text-center " data-sal="slide-down"
                  data-sal-duration="1000"
                  data-sal-easing="ease">
            <h2>
              Modern business processes require unprecedented information
              sharing
            </h2>
          </div>
        </Container>
        <div className="headingRow">
          <Container>
            <Row>
              <Col md="6"
              data-sal="slide-left"
              data-sal-duration="1000"
              data-sal-easing="ease"
              >
                <p>SECURITY</p>
              </Col>
              <Col md="6"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="300"
              >
                <p>COMPLIANCE</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contentRow">
          <Container>
            <Row>
              <Col md="6">
                <div className="contentDt">
                  <div id="aaa" className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  >43%</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    of hacks are against SMBs
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="contentDt">
                  <div className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  >7000</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    New regulations in the past 5 years
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contentRow">
          <Container>
            <Row>
              <Col md="6">
                <div className="contentDt">
                  <div className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  >424%</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    growth in cyberattacks against SMBs
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="contentDt">
                  <div className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  >2021</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    Executive order and CMMC
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contentRow">
          <Container>
            <Row>
              <Col md="6">
                <div className="contentDt">
                  <div className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  >60%</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    of companies go out of business after a cyberattack
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="contentDt">
                  <div className="content-left theme-sec heading-def"
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-easing="ease"

                  >2x</div>
                  <div className="content-right normal-text"
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  ata-sal-delay="300"
                  >
                    SMB spend on IT security
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="btn-center">
          <Container>
            <div className="text-center">
              <Link className="btn btn-primary" to="/contact">
                Learn More
              </Link>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default About;
